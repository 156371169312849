<template>
    <b-modal id="allowanceClientEdit" title="Изменить надбавку" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <label for="">Надбавки</label>
                        <v-select
                            :reduce="option => option.id"
                            :options="selectAlowances"
                            label="name"
                            v-model="createAllowance.allowance_id"
                        />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="12">
                        <draggable v-model="propertyNum">
                            <label for="">Сдача</label>
                            <b-col
                            v-for="(property, i) in propertyNum"
                            :key="i"
                            class="mb-1/2 d-flex"
                            style="margin-top: 10px;"
                            cols="12">
                                <b-button size="sm" variant="outline-primary" class=" mr-1/2"
                                    style="margin-right: 5px;"
                                    v-if="i === 0" @click="routePush">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                                <b-button size="sm" variant="outline-primary" class=" mr-1/2" style="margin-right: 5px;" v-else
                                    @click="routeMinus(i)">
                                    <feather-icon icon="MinusIcon" />
                                </b-button>
                                <b-input
                                class="w-100"
                                style="padding-left: 5px;"
                                v-model="property.name"
                                type="number"
                                ></b-input>
                            </b-col>
                        </draggable>
                    </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="add()"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="clear()">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
            </b-col>
            <b-col>
                
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' 
import draggable from 'vuedraggable'

export default {
    props: ['id','selectAlowances'],
    components: {
        vSelect,
        ToastificationContent,
        draggable
    },
    data(){
        return {
            createAllowance: [],
            submitButtonDisabled: false,
            propertyNum: []
        }
    },
    methods: {
        routePush() {
            this.propertyNum.push({name: ''})
            this.submitButtonDisabled = this.propertyNum.length > 0 ? false : true
        },
        routeMinus(id) {
            this.propertyNum.splice(id, 1);
            this.submitButtonDisabled = this.propertyNum.length > 1 ? false : true
        },
        add() {
            var err = this.propertyNum.map(function(obj) {
                return parseInt(obj.name);
            });

            this.createAllowance.property = err
            this.createAllowance.property = JSON.stringify(this.createAllowance.property);

            this.submitButtonDisabled = true
            this.$http
                .patch(`allowance-client/${this.id.id}?allowance_id=${this.createAllowance.allowance_id}&property=${this.createAllowance.property}`)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: res.data.message,
                        },
                    })
                    this.$emit('refresh')
                    this.clear()
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        clear() {
            this.createAllowance = []
            this.propertyNum = []
            this.$bvModal.hide('allowanceClientEdit')
        },
        opened() {
            this.$http.get(`allowance-client/${this.id.id}`)
                .then(res => {
                    this.createAllowance = res.data
                    res.data.property.forEach((e) => {
                        this.propertyNum.push({
                            name: e
                        })
                    });
                })
        }
    }
}
</script>