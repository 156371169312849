<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
             <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-client-allowance-vue :tariffClients="tariffClients" @refresh="refresh" @getId="getId" @editForm="editForm"/>
        </div>
        <modal-client-allowance @refresh="refresh" :selectAlowances="selectAlowances"/>
        <modal-client-allowance-edit @refresh="refresh" :id="id" :selectAlowances="selectAlowances"/>
    </div>
</template>

<script>
import tableClientAllowanceVue from '@/views/component/Table/tableClientAllowance.vue'
import ModalClientAllowance from '@/views/component/Modal/ModalClientAllowance/modalClientAllowance.vue'
import modalClientAllowanceEdit from '@/views/component/Modal/ModalClientAllowance/modalClientAllowanceEdit.vue'
export default {
    components: { tableClientAllowanceVue, ModalClientAllowance, modalClientAllowanceEdit },
    data() {
        return {
            tariffClients: [],
            selectAlowances: [],
            showPreloader: false,
            id: undefined
        }
    },
    mounted() {
        this.refresh()
        this.tariffAllowance()
    },
    methods: {
        tariffAllowance() {
            this.$http.get('tariff/allowances')
                .then(res => {
                    for(let i of res.data){
                        this.selectAlowances.push({
                            id: i.id,
                            name: i.name
                        })
                    }
                })
        },
        refresh(){
            this.showPreloader = true
            this.$http
            .get('allowance-client')
            .then(res => {
                this.tariffClients = res.data
                this.$store.commit('pageData/setdataCount', this.tariffClients.length)
                this.showPreloader = false
                this.$store.commit('REFRESH_DATA', false)
            }).catch(err => {
                console.log(err);
            })
        },
        getId(id) {
            this.id = id
        },
        openModal() {
            this.$bvModal.show("allowanceClientEdit");
        },
        editForm(id) {
            this.id = id;
            setTimeout(this.openModal, 0);
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.$http
                .get(`allowance-client`, {params: param})
                .then(res =>{
                    this.tariffClients = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffClients.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>

<style scoped>

</style>