<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить надбавку" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @close="clear">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <label for="">Надбавки</label>
                        <v-select
                            :options="selectAlowances"
                            :reduce="option => option.id"
                            label="name"
                            v-model="createAllowance.allowance_id"
                        />
                    </b-col>
                </b-row>
                <b-row class="">
                    <b-col cols="12">
                        <draggable v-model="properties">
                        <label for="">Сдача</label>
                            <b-col
                            v-for="(property, id) in properties"
                            :key="id"
                            class="mb-1/2 d-flex"
                            style="margin-top: 10px;"
                            cols="12">
                                <b-button size="sm" variant="outline-primary" class=" mr-1/2"
                                    style="margin-right: 5px;"
                                    v-if="id === 0" @click="routePush">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                                <b-button size="sm" variant="outline-primary" class=" mr-1/2" style="margin-right: 5px;" v-else
                                    @click="routeMinus(id)">
                                    <feather-icon icon="MinusIcon" />
                                </b-button>
                                <b-input
                                class="w-100"
                                style="padding-left: 5px;"
                                v-model="property.num"
                                type="number"
                                ></b-input>
                            </b-col>
                        </draggable>
                    </b-col>
                </b-row>
                <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="add()"
                    >
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="clear()">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
            </b-col>
            <b-col>
                
            </b-col>
        </b-row>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' 
import draggable from 'vuedraggable'

export default {
    components: {
        vSelect,
        ToastificationContent,
        draggable
    },
    props: ['selectAlowances'],
    data(){
        return {
            createAllowance : {
                allowance_id: null,
                property: []
            },
            properties: [{
                num: ''
            }],
            submitButtonDisabled: false,
        }
    },
    methods: {
        routePush() {
            this.properties.push({num: ''})
            if (this.properties.length >= 0) {
                this.submitButtonDisabled = false
            } else {
                this.submitButtonDisabled = true
            }
        },
        routeMinus(id) {
            let deleted = this.properties.splice(id, 1);
            if (this.properties.length > 1) {
                this.submitButtonDisabled = false
            } else {
                this.submitButtonDisabled = true
            }
        },
        add() {
            var err = this.properties.map(function(obj) {
                return parseInt(obj.num);
            });
            this.createAllowance.property = err
            this.createAllowance.property = JSON.stringify(this.createAllowance.property);
            this.submitButtonDisabled = true
            this.$http
                .post('allowance-client', this.createAllowance)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Надбавка успешно добавлен!`,
                        },
                    })
                    this.$emit('refresh')
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
                this.createAllowance.allowance_id = null
                this.properties = [{num: ''}]
        },
        clear() {
            this.createAllowance.allowance_id = null
            this.properties = [{num: ''}]

            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
    }
}
</script>